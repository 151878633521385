<template>
  <v-container id="conductores" fluid tag="section">
    <v-card>
      <v-card-text>
        <v-card outlined>
          <v-card-subtitle>
            <v-btn
              color="primary"
              @click="showPanel = !showPanel"
              text
              :title="
                !showPanel
                  ? 'Abrir panel de Consulta de ' + title
                  : 'Cerrar panel de Consulta de ' + title
              "
              class="text-left"
            >
              Consulta de {{ title }}
            </v-btn>
          </v-card-subtitle>
          <v-expand-transition>
            <v-card-text v-show="showPanel">
              <v-form ref="formBusqueda" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" md="4" sm="12">
                    <v-text-field
                      v-model="formSearch.dni"
                      label="Documento"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <v-text-field
                      v-model="formSearch.apellido"
                      label="Apellido"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <v-text-field
                      v-model="formSearch.nombres"
                      label="Nombres"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" sm="12">
                    <v-autocomplete
                      :items="zonas"
                      item-value="id"
                      item-text="nombre"
                      label="Zona"
                      v-model="formSearch.zona_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <v-autocomplete
                      :items="deptos"
                      item-value="id"
                      item-text="nombre"
                      label="Departamento"
                      v-model="formSearch.depto_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <v-autocomplete
                      :items="cargos"
                      item-value="id"
                      item-text="nombre"
                      label="Cargo"
                      v-model="formSearch.cargo_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="12" sm="12" justify="end" align="right">
                    <v-btn
                      color="primary"
                      small
                      @click="
                        page = 1;
                        initialize();
                      "
                    >
                      Buscar
                    </v-btn>
                    <v-btn color="red" small @click="reset"> Reset </v-btn>
                  </v-col>
                </v-row>
                <v-container fill-height fluid>
                  <v-row align="center" justify="center">
                    <v-col></v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-expand-transition>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :loading="loading"
          class="elevation-1"
          :options.sync="pagination"
          :server-items-length="total"
          :footer-props="footerProps"
        >
          <template v-slot:item.vencimiento_licencia="{ item }">
            {{ formatDate(item.vencimiento_licencia) }}
          </template>

          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Lista de {{ title }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="50%" persistent>
                <template v-if="visible" v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mx-2"
                    fab
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon blue>mdi-plus</v-icon></v-btn
                  >
                </template>
                <v-card>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-toolbar dark color="primary">
                      <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn dark text @click="save()" :disabled="!valid"
                          >Guardar</v-btn
                        >
                      </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text class="card-scrolleable">
                      <v-container>
                        <v-row>
                          <v-col md="6" sm="12">
                            <v-text-field
                              v-model="editedItem.dni"
                              label="Documento"
                              :rules="rulesDni"
                              hint="Ingrese solo números"
                              filled
                              dense
                              class="input-h1"
                            ></v-text-field>
                          </v-col>

                          <v-col md="6" sm="12">
                            <v-text-field
                              v-model="editedItem.legajo"
                              label="Legajo"
                              :rules="rulesLegajo"
                              hint="Ingrese solo números"
                              filled
                              dense
                              class="input-h1"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="4" sm="12">
                            <v-text-field
                              v-model="editedItem.nombre"
                              label="Nombre"
                              :rules="rulesRequired"
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" sm="12">
                            <v-text-field
                              v-model="editedItem.apellido"
                              label="Apellido"
                              :rules="rulesRequired"
                              filled
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="4" sm="12">
                            <v-text-field
                              v-model="editedItem.mail"
                              label="Email"
                              :rules="rulesMail"
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="6" sm="6">
                            <v-autocomplete
                              :items="tipoLicencias"
                              item-value="id"
                              item-text="nombre"
                              label="Tipo de Licencia"
                              v-model="editedItem.tipo_licencia_id"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6" sm="6">
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="
                                editedItem.vencimiento_licencia
                              "
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="computedVencimientoLicencia"
                                  label="Vencimiento Licencia"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="text-red"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.vencimiento_licencia"
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.dialog.save(
                                      editedItem.vencimiento_licencia
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="4" sm="12">
                            <v-autocomplete
                              :items="zonas"
                              item-value="id"
                              item-text="nombre"
                              label="Zona"
                              v-model="editedItem.zona_id"
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="12" md="4" sm="12">
                            <v-autocomplete
                              :items="deptos"
                              item-value="id"
                              item-text="nombre"
                              label="Departamento"
                              v-model="editedItem.departamento_id"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="4" sm="12">
                            <v-autocomplete
                              :items="cargos"
                              item-value="id"
                              item-text="nombre"
                              label="Cargo"
                              v-model="editedItem.cargo_id"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6" sm="6">
                            <v-text-field
                              v-model="editedItem.secretaria"
                              label="Secretaria"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6" sm="6">
                            <v-text-field
                              v-model="editedItem.subsecretaria"
                              label="Subsecretaria"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-spacer></v-spacer>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="visible"
              small
              class="mr-2"
              color="green"
              title="Editar"
              @click="$editItem(item.id)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="visible"
              small
              color="red"
              title="Eliminar"
              @click="deleteItem(item.id, item.nombre)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="text-right">
            <download-excel
              class="btn btn-rounded btn-info"
              :fields="json_fields"
              :fetch="fetchData"
              :type="dataExportType"
              :name="
                'export-' + title + '-' + this.getDate() + '.' + dataExportType
              "
            >
              Exportar Excel
            </download-excel>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <!-- <vue-confirm-dialog></vue-confirm-dialog> -->
  </v-container>
</template>

<script>
function title() {
  return "Conductores";
}

export default {
  data: (vm) => ({
    zonas: [],
    deptos: [],
    cargos: [],
    tipoLicencias: [],
    dates: [],
    formSearch: {},
    pagination: {},
    total: 0,
    loading: true,
    footerProps: { "items-per-page-options": [5, 10, 15, 30] },
    menuFecha: false,
    showPanel: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    valid: true,
    _method: "PUT",
    autoGrow: true,
    rows: 1,
    title: title(),
    route: "conductores",
    urlExport: "/api/conductores-export",
    dataExportType: "xls",
    json_fields: {
      nombre: "nombre",
      apellido: "apellido",
      dni: "dni",
      legajo: "legajo",
      mail: "mail",
      zona: "zona",
      departamento: "departamento",
      cargo: "cargo",
      tipo_licencia: "tipo_licencia",
      secretaria: "secretaria",
      subsecretaria: "subsecretaria",
    },
    menu: false,
    modal: false,
    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
    search: "",
    rulesRequired: [(v) => !!v || "Requerido"],
    rulesDni: [
      (v) => !!v || "Requerido",
      (v) => {
        if (typeof v !== "undefined") {
          const pattern = /\d{6,8}/;
          return pattern.test(v) || "Documento inválido. Ingrese solo números";
        }
      },
      (v) => {
        if (typeof v !== "undefined") {
          return v.toString().length <= 8 || "Max 8 caracteres";
        }
      },
      (v) => {
        if (typeof v !== "undefined") {
          return v.toString().length >= 6 || "Min 6 caracteres";
        }
      },
    ],
    rulesLegajo: [
      (v) => !!v || "Requerido",
      (v) => {
        if (typeof v !== "undefined") {
          const pattern = /\d{1,4}/;
          return pattern.test(v) || "Legajo inválido. Ingrese solo números";
        }
      },
      (v) => {
        if (typeof v !== "undefined") {
          return v.toString().length <= 4 || "Max 4 caracteres";
        }
      },
      (v) => {
        if (typeof v !== "undefined") {
          return v.toString().length >= 1 || "Min 1 caracteres";
        }
      },
    ],
    rulesMail: [
      (v) => {
        if (typeof v !== "undefined") {
          const pattern =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Mail inválido.";
        }
      },
      (v) => {
        if (typeof v !== "undefined") {
          return v.length <= 50 || "Max 50 caracteres";
        }
        return true;
      },
    ],
    users: false,
    headers: [
      { text: "Documento", filterable: true, value: "dni" },
      { text: "Apellido", filterable: true, value: "apellido" },
      { text: "Nombres", filterable: true, value: "nombre" },
      { text: "Legajo", filterable: true, value: "legajo" },
      { text: "Zona", filterable: true, value: "zona" },
      { text: "Departamento", filterable: true, value: "departamento" },
      { text: "Cargo", filterable: true, value: "cargo" },
      { text: "Tipo Licencia", filterable: true, value: "tipo_licencia" },
      {
        text: "Vencimiento Licencia",
        filterable: true,
        value: "vencimiento_licencia",
      },
      { text: "Secretaria", filterable: true, value: "secretaria" },

      { text: "Subsecretaria", filterable: true, value: "subsecretaria" },

      { text: "Acciones", value: "actions", sortable: false },
    ],

    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      dni: "",
      legajo: "",
      apellido: "",
      nombre: "",
      mail: "",
      secretaria: "",
      subsecretaria: "",
      tipo_licencia_id: "",
      vencimiento_licencia: "",
      departamento_id: "",
      cargo_id: "",
      zona_id: "",
    },
    defaultItem: {},
  }),

  computed: {
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (date) {
        return date;
      },
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : "Editar " + this.title;
    },
    computedVencimientoLicencia() {
      return this.$formatDate(this.editedItem.vencimiento_licencia);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    pagination: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },

  created() {
    this.initialize();
    this.getZonas();
    this.getDeptos();
    this.getCargos();
    this.getTipoLicencias();
  },

  methods: {
    getZonas() {
      this.$axiosApi
        .get("zonas")
        .then((response) => {
          this.zonas = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener zonas. Error: " + response.message;
          this.color = "error";
        });
    },
    getDeptos() {
      this.$axiosApi
        .get("departamentos")
        .then((response) => {
          this.deptos = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text =
            "Error al obtener departamentos. Error: " + response.message;
          this.color = "error";
        });
    },
    getCargos() {
      this.$axiosApi
        .get("cargos")
        .then((response) => {
          this.cargos = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener cargos. Error: " + response.message;
          this.color = "error";
        });
    },
    getTipoLicencias() {
      this.$axiosApi
        .get("tipo_licencias")
        .then((response) => {
          this.tipoLicencias = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text =
            "Error al obtener tipos de licencias. Error: " + response.message;
          this.color = "error";
        });
    },
    getDate() {
      return Math.floor(Date.now() / 1000);
    },
    async fetchData() {
      let query = this.formSearch;
      let formData = new FormData();
      formData = this.getQryParams(query);
      let queryParams = new URLSearchParams(formData).toString();
      let url = this.urlExport;
      const response = await axios.get(url + "?" + queryParams);
      return response.data.data;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year}`;
    },

    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },

    editItem(id) {
      axios
        .get(this.baseUrl + "/" + id)
        .then((r) => {
          this.respuesta(r, 3);
        })
        .catch(function (error) {
          this.respuesta(error, 3, true);
        });
    },

    deleteItem(id, nombre) {
      this.$axiosApi
        .delete(this.route, id)
        .then((r) => {
          this.respuesta(r, 0);
        })
        .catch((e) => {
          this.respuesta(e, 0, true);
        });
    },

    reset() {
      this.dates = [];
      this.$refs.formBusqueda.reset();
      this.initialize();
    },
    getQryParams(dataSearch) {
      let formData = new FormData();

      if (dataSearch.zona_id) {
        formData.append("zona_id", dataSearch.zona_id);
      }
      if (dataSearch.depto_id) {
        formData.append("depto_id", dataSearch.depto_id);
      }
      if (dataSearch.cargo_id) {
        formData.append("cargo_id", dataSearch.cargo_id);
      }
      if (dataSearch.id) {
        formData.append("id", dataSearch.id);
      }
      if (dataSearch.dni) {
        formData.append("dni", dataSearch.dni);
      }
      if (dataSearch.apellido) {
        formData.append("apellido", dataSearch.apellido);
      }
      if (dataSearch.nombres) {
        formData.append("nombres", dataSearch.nombres);
      }
      if (dataSearch.estado_id) {
        formData.append("estado_id", dataSearch.estado_id);
      }
      if (this.dates.length > 0) {
        formData.append("created_at_start", this.dates[0]);
        formData.append("created_at_end", this.dates[1]);
      }
      if (dataSearch.tipo_licencia_id) {
        formData.append("tipo_licencia_id", dataSearch.tipo_licencia_id);
      }
      if (dataSearch.vencimiento_licencia) {
        formData.append(
          "vencimiento_licencia",
          dataSearch.vencimiento_licencia
        );
      }

      return formData;
    },
    initialize() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let url = this.route;

      if (this.formSearch && !sortBy) {
        let formData = new FormData();
        formData = this.getQryParams(this.formSearch);
        let query = new URLSearchParams(formData).toString();
        query =
          query +
          "&page=" +
          this.pagination.page +
          "&per_page=" +
          this.pagination.itemsPerPage;

        this.$axiosApi
          .getByQuery(url, query)
          .then((r) => {
            this.desserts = r.data.data;
            this.pagination.current = r.data.current_page;
            this.total = r.data.total;
            this.loading = false;
          })
          .catch(function (error) {
            this.snackbar = true;
            this.text = "Error al obtener conductores. Error: " + error;
            this.color = "error";
          })
          .finally(() => (this.loading = false));

        return;
      }

      if (this.formSearch && sortBy) {
        let formData = new FormData();
        formData = this.getQryParams(this.formSearch);

        let query = new URLSearchParams(formData).toString();
        let direction = "desc";
        //sortDesc[0] == true || sortDesc[0] == undefined ? "desc" : "asc";
        query =
          query +
          "&page=" +
          this.pagination.page +
          "&per_page=" +
          this.pagination.itemsPerPage +
          "&sortBy=" +
          this.pagination.sortBy +
          "&direction=" +
          direction;

        this.$axiosApi
          .getByQuery(url, query)
          /*
        axios
          .get(
            url +
              "?" +
              query +
              "&page=" +
              this.pagination.page +
              "&per_page=" +
              this.pagination.itemsPerPage +
              "&sortBy=" +
              this.pagination.sortBy +
              "&direction=" +
              direction
          )
          */
          .then((r) => {
            this.desserts = r.data.data;
            this.pagination.current = r.data.current_page;
            this.total = r.data.total;
            this.loading = false;
          })
          .catch(function (error) {
            this.loading = false;
            this.snackbar = true;
            this.text = "Error al obtener conductores. Error: " + error;
            this.color = "error";
          })
          .finally(() => (this.loading = false));

        return;
      }

      if (sortBy && !this.formSearch) {
        let direction =
          sortDesc[0] == true || sortDesc[0] == undefined ? "desc" : "asc";
        let query =
          "?direction=" +
          direction +
          "&sortBy=" +
          this.pagination.sortBy +
          "&page=" +
          this.pagination.page +
          "&per_page=" +
          this.pagination.itemsPerPage;

        this.$axiosApi
          .getByQuery(url, query)
          .then((r) => {
            this.desserts = r.data.data;
            this.pagination.current = r.data.current_page;
            this.total = r.data.total;
            this.loading = false;
          })
          .catch(function (error) {
            this.loading = false;
            this.snackbar = true;
            this.text = "Error al obtener conductores. Error: " + error;
            this.color = "error";
          })
          .finally(() => (this.loading = false));
      }

      if (!this.formSearch && !sortBy) {
        //console.log('no hay parametros');
        this.$axiosApi
          .getByQuery(url, query)
          .then((r) => {
            //console.log(r.data);
            this.desserts = r.data.data;
            this.pagination.current = r.data.current_page;
            this.total = r.data.total;
            this.loading = false;
          })
          .catch(function (error) {
            this.loading = false;
            this.snackbar = true;
            this.text = "Error al obtener conductores. Error: " + error;
            this.color = "error";
          })
          .finally(() => (this.loading = false));
      }
    },

    close() {
      this.dialog = false;
      this.valid = true;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          let formData = new FormData();
          let url = this.baseUrl + "/" + this.editedIndex;
          //formData.append("_method", "PUT");
          this.$axiosApi
            .put(this.route, this.editedIndex, this.editedItem)
            /*axios
            .post(url, {
              _method: "PUT",
              data: this.editedItem,
            })*/
            .then((r) => {
              this.respuesta(r, 2);
            })
            .catch((e) => {
              this.respuesta(e, 2, true);
            });
        } else {
          let method = "post";
          let url = this.baseUrl;
          /*
          axios({
            method,
            url,
            data: this.editedItem,
          })*/
          this.$axiosApi
            .post(this.route, this.editedItem)
            .then((r) => {
              this.respuesta(r, 1);
            })
            .catch((e) => {
              this.respuesta(e, 1, true);
            });
        }
      }
    },

    respuesta(r, tipo = 1, excepcion = false) {
      let text1 = "Creado";
      let text2 = "crear";
      switch (tipo) {
        case 0:
          text1 = "Eliminado";
          text2 = "eliminar";
          break;
        case 2:
          text1 = "Actualizado";
          text2 = "actualizar";
          break;
        case 3:
          text1 = "Obtener";
          text2 = "actualizar";
          break;
      }
      if (excepcion == false) {
        if (tipo == 3) {
          if (r.data && r.data.status == 200) {
            this.editedIndex = r.data.data.id;
            delete r.data.data.id;
            this.editedItem = Object.assign({}, r.data.data);
            this.dialog = true;
          } else {
            console.log(r);
            this.snackbar = true;
            this.text =
              "<p>Ocurrió un problema al recuperar el registro.<br><hr><b>Codigo:</b>" +
              r.data.code +
              " " +
              r.data.message +
              "</p>";
            this.color = "error";
          }
        } else {
          if (r.data && r.data.status == 200) {
            this.color = "success";
            this.snackbar = true;
            this.text = "Registro " + text1;
            this.close();
            this.initialize();
          } else {
            this.snackbar = true;
            this.text =
              "<p>Ocurrió un problema al " +
              text2 +
              " el registro.<br><hr><b>Codigo:</b>" +
              r.data.code +
              " " +
              r.data.message +
              "</p>";
            this.color = "error";
          }
        }
      } else {
        console.log(r);
        this.snackbar = true;
        this.text = "Error al " + text2 + " los datos. Error: " + r.message;
        this.color = "error";
      }
    },
  },
  mounted() {
    console.log("Componente " + title() + " creado");
  },
};
</script>
